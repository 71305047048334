import React from 'react';
import { Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
//Img
import 'rsuite/dist/rsuite.min.css';
import 'react-tagsinput/react-tagsinput.css'
import Icon_Manage from '../../image/sidebar_icon/manageBook_b.png';
import Icon_Account from '../../image/sidebar_icon/account_b.png';
import Icon_Financial from '../../image/sidebar_icon/financial_b.png';
import Icon_Report from '../../image/sidebar_icon/chart_b.png';
import Icon_Contract_Agreement from '../../image/sidebar_icon/contract_b.png';
import IconBooksApprovalGra from '../../image/sidebar_icon/BooksApproval_Gray.png'; 
import IconCategoriesGray from '../../image/sidebar_icon/List_Gray.png'; 
import IconBannerGray from '../../image/sidebar_icon/Banner_Gray.png'; 
import IconShelfManagementGray from '../../image/sidebar_icon/Shelf_Gray.png'; 
import Icon_ManageReview from '../../image/sidebar_icon/Icon_ManageReview.png'; 
import IconCustomerSupportGray from '../../image/sidebar_icon/Icon_CustomerSupportGray.png'; 
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import { useDispatch, useSelector } from 'react-redux';
import { setCurrentManageBookPage , setCurrentBooksApprovalInProgressPage , setCurrentBooksApprovalReadyofSalePage , setManageSortOption , setManageReviewSortOption , setBooksApprovalSortOption} from '../../actions/site.action.js';



function Navbar() {
  const dispatch = useDispatch();


  var focusMenuManageBook = 0;
  var focusMenuAccountSetting = 1;
  var focusMenuFinancial = 2;
  var focusMenuReport = 3;
  var focusMenuContract = 4;

  var focusMenageBooksApproval = 5;
  var focusCategories = 6;
  var focusBanner = 7;
  var focusPagesShelf = 8;
  var focusManageReview = 9;
  var focusCustomerSupport = 10;
  var focusMenuReportAdmin = 11;
  

  let menuMenageBookItems = [
    "/ManageBooks",
    "/AddBooks",
    "/EditEbooks",
    "/ViewEbooks",
  ];

  let menuAccountSettingsItems = [
    "/AccountSettings"
  ];

  let menuFinancialItems = [
    "/Financial",
    "/ViewFinancial"
  ];

  let menuReportItems = [
    "/Report"
  ];

  let menuContractItems = [
    "/Contract"
  ];


  let menuMenageBooksApproval = [
    "/BooksApproval",
    "/ViewEbooksAdmin",
  ];

  let menuCategories = [
    "/Categories",
    "/AddCategories",
    "/EditCategories"
  ];

  let menuBanner = [
    "/Banner",
    "/AddBanner",
    "/EditBanner"
  ];

  let menuPagesShelf = [
    "/PagesShelf",
    "/ShelfManagement",
    "/ManageShelf",
    "/EditShelfManagement",
    "/ManagePageShelf"
  ];

  let menuManageReview = [
    "/ManageReview"
  ];

  let menuCustomerSupport = [
    "/CustomerSupport"
  ];

  let menuReportAdmin = [
    "/ReportAdmin"
  ];
  
  

  const userLevel = useSelector(state => state.user.userLevel) || "";
  const isImpersonatedLogin = useSelector(state => state.user.isImpersonatedLogin);

  const [selectedMenuPath, setSelectedMenuPath] = useState("/ManageBooks"); // Set initial path
  const [shouldProcessNext , setShouldProcessNext] = useState(true);
  const [focusMenuIndex , setFocusMenuIndex] = useState(focusMenuManageBook);
  
  useEffect(() => {
    let currentMenuPath = window.location.pathname; // จะ pills ไปตาม URL ที่เรากด

    if (menuMenageBookItems.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenuManageBook);
      setSelectedMenuPath('/ManageBooks');
      setShouldProcessNext(false);
    } else {
      dispatch(setCurrentManageBookPage(1)); // เช็ค Page Ebooks เป็นหน้า 1
      dispatch(setManageSortOption([])); // set ManageBookSort ให้เป็นค่าว่าง
    }

    if (shouldProcessNext && menuAccountSettingsItems.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenuAccountSetting);
      setSelectedMenuPath("/AccountSettings");
      setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuFinancialItems.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenuFinancial);
      setSelectedMenuPath("/Financial");
      setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuReportItems.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenuReport);
      setSelectedMenuPath("/Report");
      setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuContractItems.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenuContract);
      setSelectedMenuPath("/Contract");
      setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuMenageBooksApproval.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenageBooksApproval);
      setSelectedMenuPath("/BooksApproval");
        setShouldProcessNext(false);
    } else {
      dispatch(setCurrentBooksApprovalInProgressPage(1)); // เช็ค Page  เป็นหน้า 1
      dispatch(setCurrentBooksApprovalReadyofSalePage(1)); 
      dispatch(setBooksApprovalSortOption([])); 
    }

    if (shouldProcessNext && menuCategories.includes(currentMenuPath)) {
      setFocusMenuIndex(focusCategories);
      setSelectedMenuPath("/Categories");
        setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuBanner.includes(currentMenuPath)) {
      setFocusMenuIndex(focusBanner);
      setSelectedMenuPath("/Banner");
        setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuPagesShelf.includes(currentMenuPath)) {
      setFocusMenuIndex(focusPagesShelf);
      setSelectedMenuPath("/PagesShelf");
        setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuManageReview.includes(currentMenuPath)) {
      setFocusMenuIndex(focusManageReview);
      setSelectedMenuPath("/ManageReview");
        setShouldProcessNext(false);
    } else {
      dispatch(setManageReviewSortOption([])); 
    }

    if (shouldProcessNext && menuCustomerSupport.includes(currentMenuPath)) {
      setFocusMenuIndex(focusCustomerSupport);
      setSelectedMenuPath("/CustomerSupport");
        setShouldProcessNext(false);
    }

    if (shouldProcessNext && menuReportAdmin.includes(currentMenuPath)) {
      setFocusMenuIndex(focusMenuReportAdmin);
      setSelectedMenuPath("/ReportAdmin");
        setShouldProcessNext(false);
    }


    

  }, [selectedMenuPath]); // Empty dependency array ensures this runs once on mount


  return (

    <div className='col-siz'>
      <Col >
        <Nav variant="pills" defaultActiveKey={selectedMenuPath}>
          <div className='row-nav-link'>
            <span className='text-menu'>MENU</span>

            {userLevel === 'guest' && (
              <div>
                <Nav.Item>
                  <Link to="/ManageBooks" replace className={"menu-nav-item" + (focusMenuIndex == focusMenuManageBook ? ' active' : '')} style={{ textDecoration: 'none' , marginTop: '10px', marginBottom: '10px'}} eventkey="/ManageBooks"   >  <img src={Icon_Manage} className='icon-nav' /> Manage Books</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/AccountSettings" replace className={"menu-nav-item" + (focusMenuIndex == focusMenuAccountSetting ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/AccountSettings"  > <img src={Icon_Account} className='icon-nav' /> Account Settings</Link>
                </Nav.Item>

                {/* ถ้าสวบสิทธิ์Login เข้ามาจะ ซ่อน Financial */}
                {!isImpersonatedLogin && (
                <Nav.Item>
                  <Link to="/Financial" replace className={"menu-nav-item" + (focusMenuIndex == focusMenuFinancial ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/Financial"  ><img src={Icon_Financial} className='icon-nav' /> Financial</Link>
                </Nav.Item>
                )}
                
                <Nav.Item>
                  <Link to='/Report' replace className={"menu-nav-item" + (focusMenuIndex == focusMenuReport ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/Report"  ><img src={Icon_Report} className='icon-nav' /> Report</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to='/Contract' replace className={"menu-nav-item" + (focusMenuIndex == focusMenuContract ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} ><img src={Icon_Contract_Agreement} className='icon-nav' /> Contract/Agreement</Link>
                </Nav.Item>
              </div>
            )}

            {userLevel === 'admin' && (

              <div>
                <Nav.Item>
                  <Link to="/BooksApproval" replace className={"menu-nav-item" + (focusMenuIndex == focusMenageBooksApproval ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/BooksApproval"  > <img src={IconBooksApprovalGra} className='icon-nav' /> Books Approval</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/Categories" replace className={"menu-nav-item" + (focusMenuIndex == focusCategories ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/Categories"  ><img src={IconCategoriesGray} className='icon-nav' /> Categories</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/Banner" replace className={"menu-nav-item" + (focusMenuIndex == focusBanner ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/banner"  ><img src={IconBannerGray} className='icon-nav' /> Banner</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/PagesShelf" replace className={"menu-nav-item" + (focusMenuIndex == focusPagesShelf ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/ShelfManagement"  ><img src={IconShelfManagementGray} className='icon-nav' /> Shelf Management</Link>
                </Nav.Item>

                <Nav.Item>
                  <Link to="/ManageReview" replace className={"menu-nav-item" + (focusMenuIndex == focusManageReview ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/ManageReview"  ><img src={Icon_ManageReview} className='icon-nav' /> Manage Review</Link>
                </Nav.Item>
                
                <Nav.Item>
                  <Link to="/CustomerSupport" replace className={"menu-nav-item" + (focusMenuIndex == focusCustomerSupport ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/CustomerSupport"  ><img src={IconCustomerSupportGray} className='icon-nav' /> Customer Support</Link>
                </Nav.Item>
                
                
                <Nav.Item>
                  <Link to='/ReportAdmin' replace className={"menu-nav-item" + (focusMenuIndex == focusMenuReportAdmin ? ' active' : '')} style={{ textDecoration: 'none', marginTop: '10px', marginBottom: '10px'}} eventkey="/Report"  ><img src={Icon_Report} className='icon-nav' /> Report</Link>
                </Nav.Item>
                
                
              </div>
            )}


          </div>
        </Nav>
      </Col>
    </div>







  );
};

export default Navbar;
