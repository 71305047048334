
import React, { useState, useEffect,useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import { getPublisherDetail } from '../../api'
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import { resetPassword, getProvince, getBank, updatePublisherDetail, getBankAccount, getPublisher, uploadFileCompany, updateCompanyCertFilePath } from '../../api'
import { isNumeric, isLength } from 'validator';
import { Alert } from 'react-bootstrap';
import accessPath from '../../api/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { hideLoading, isPublisherLevel, showLoading } from '../../helper/Helper.js';

const AccountSettings = () => {
    const resetPasswordFormRef = useRef();
    const currentPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();
    const companyRef = useRef();
    const accountEmailRef = useRef();

    const [formData, setFormData] = useState('');
    const [resetPasswordFormData, setResetPasswordFormData] = useState('');
    const [publisher, setpublisher] = useState([]);
    const [publisherAddress, setpublisherAddress] = useState([]);
    const [publisherAddressDocument, setpublisherAddressDocument] = useState([]);
    const [publisherBankAccount, setpublisherBankAccount] = useState([]);
    const [publisherAccountingDepartment, setPublisherAccountingDepartment] = useState([]);
    const [optionsProvince, setOptionsProvince] = useState([]);
    const [optionsProvince2, setOptionsProvince2] = useState([]);
    const [optionsProvince3, setOptionsProvince3] = useState([]);
    const [idCardError, setIdCardError] = useState('');
    const [taxIdError, settaxIdError] = useState('');
    const [zipCodeError, setzipCodeError] = useState('');
    const [zipCodeDocmentError, setzipCodeDocmentError] = useState('');
    const [accountNumberError, setaccountNumberError] = useState('');
    const [AlertError, setAlertErrorError] = useState('');
    const [shouldShowSaveSuccessMessage, setShouldShowSaveSuccessMessage] = useState(false);
    const [shouldShowResetPasswordError, setShouldShowResetPasswordError] = useState(false);
    const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState('');
    const [uploadCompany, setUploadCompany] = useState('');
    const [fileCompanyCert, setFileCompanyCert] = useState(null);
    const [selectedFileCoverType, setSelectedFileTyoe] = useState('');
    const [showUploadImageErrorMessage, setUploadImageErrorMessage] = useState('');
    const [showAlertError, setShowAlertError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [optionsBank, setOptionsBank] = useState([]);
    const [optionsgetBankAccount, setOptionsgetBankAccount] = useState([]);
    const [personalCorporation, setpersonalCorporation] = useState(''); // เก็บสถานะ personalCorporation ที่เลือกอยู่
    const [selectedAddress, setSelectedAddress] = useState(''); // เก็บสถานะ inputAddress ที่เลือกอยู่
    const [activeKey, setActiveKey] = useState('home');

    const dispatch = useDispatch();
    const formRef = useRef(null);
    const isImpersonatedLogin = useSelector(state => state.user.isImpersonatedLogin);

    const handleImageChange = (event) => {

        const acceptedTypes = ["image/jpeg", "image/jpg", "application/pdf"];
        const file = event.target.files[0];

        if (!acceptedTypes.includes(file.type)) {
            // alert("ไฟล์รูปภาพต้องเป็น .jpg, .jpeg, หรือ .png เท่านั้น");
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: "Supported file extensions .jpg .jpeg .pdf only",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // เคลียร์ input
            companyRef.current.value = null;
            return;
        }

        if (file.type === "application/pdf") {
            setSelectedFileTyoe(true);
          } else {
            setSelectedFileTyoe(false);
          }

        setFileCompanyCert(file);
        setUploadCompany(URL.createObjectURL(file));

    };

    function resetCompanyCertFileUploadForm(){
        setFileCompanyCert(null);
    }

    const handleClickPdf = () => {
        window.open(uploadCompany, '_blank');
    };

    const deleteCompany = () => {
        dispatch(dialogConfirmSetData({
            title: "Confirm clear",
            message: "This will remove the selected file from the upload.",
            closeButton: "Cancel",
            confirmButton: "Remove",
            eventConfirmButton: () =>  setUploadCompany(""),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    }

    function showProvince() {
        getProvince().then(handleShowProvinceResponse)
    }
    const handleShowProvinceResponse = async (response) => {
        setOptionsProvince(response.data);
        setOptionsProvince2(response.data);
        setOptionsProvince3(response.data);
    }

    //get ธนาคาร getBank
    function showBank() {
        getBank().then(handleShowBankResponse)
    }
    const handleShowBankResponse = async (response) => {
        setOptionsBank(response.data);
    }
    //===========================================================//

    //get ธนาคาร getBankAccount
    function showgetBankAccount() {
        getBankAccount().then(handleShowgetBankAccountResponse)
    }
    const handleShowgetBankAccountResponse = async (response) => {
        setOptionsgetBankAccount(response.data);
    }
    //===========================================================//

    function showgetPublisherDetail() {
        getPublisherDetail().then(handleShowgetPublisherDetailResponse)
    }
    const handleShowgetPublisherDetailResponse = async (response) => {
        setpublisher(response.data['publisher']);
        let CoverImagePath = accessPath + response.data['publisher']['id_card_image_path'];
        if (response.data['publisher']['id_card_image_path'] != null) {
            setUploadCompany(CoverImagePath);
        } else {
            setUploadCompany("");
        }

        const textToSearch = response.data['publisher']['id_card_image_path'];
        const hasPdf = textToSearch && textToSearch.toLowerCase().includes("pdf");

        if (hasPdf) {
            setSelectedFileTyoe(true);
        } else {
            setSelectedFileTyoe(false);
        }

        if (response.data['publisher']['publisher_type_id'] == 1) {
            setpersonalCorporation('1');
        } else if (response.data['publisher']['publisher_type_id'] == 2) {
            setpersonalCorporation('2');
        }
        if (response.data['publisher']['use_same_address_as_id_card'] == 1) {
            setSelectedAddress('1');
        } else if (response.data['publisher']['use_same_address_as_id_card'] == 2) {
            setSelectedAddress('2');
        }

        setpublisherAddress(response.data['publisherAddress']);
        setpublisherBankAccount(response.data['publisherBankAccount']);
        setpublisherAddressDocument(response.data['publisherAddressdocumentDeliveryAddressId']);
        setPublisherAccountingDepartment(response.data['publisherAccountingDepartment']);

    }

    useEffect(() => {
        
        showgetPublisherDetail();
        showBank();
        showgetBankAccount();
        showProvince();
        setAlertErrorError('');
        
    }, []);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    //รับค่าจาก ฟอม
    const handleChangepersonalCorporation = (event) => {
        // setpersonalCorporation(event.target.value);
        if (event.target.value == 1) {
            setpersonalCorporation('1');
        } else if (event.target.value == 2) {
            setpersonalCorporation('2');
        }

    };

    const handleChangeAddress = (event) => {
        setSelectedAddress(event.target.value);
    };

    const handleChangeResetPasswordForm = (event) => {

        const { name, value } = event.target || '';
        setResetPasswordFormData((prevData) => ({ ...prevData, [name]: value }));

    };

    const handleChange = (event) => {

        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        // console.log(formData);

        if (name == 'idCard') {
            if (!isNumeric(value)) {
                setIdCardError('Please enter numbers only.');
            } else if (!isLength(value, { min: 13, max: 13 })) {
                setIdCardError('Please enter 13 digits');
            } else {
                setIdCardError('');
            }
        }

        if (name == 'taxId') {
            if (!isNumeric(value)) {
                settaxIdError('Please enter numbers only.');
            } else if (!isLength(value, { min: 13, max: 13 })) {
                settaxIdError('Please enter 13 digits');
            } else {
                settaxIdError('');
            }
        }



        if (name == 'zipCode') {
            if (!isNumeric(value)) {
                // setzipCodeError('Please enter numbers only.');
            } else if (!isLength(value, { min: 5, max: 5 })) {
                setzipCodeError('Please enter 5 digits');
            } else {
                setzipCodeError('');
            }
        }


        if (name == 'zipCodeDocment') {
            if (!isNumeric(value)) {
                // setzipCodeDocmentError('Please enter numbers only.');
            } else if (!isLength(value, { min: 5, max: 5 })) {
                setzipCodeDocmentError('Please enter 5 digits');
            } else {
                setzipCodeDocmentError('');
            }
        }


        if (name == 'accountNumber') {
            if (!isNumeric(value)) {
                setaccountNumberError('Please enter numbers only.');
            } else if (!isLength(value, { min: 10, max: 10 })) {
                // setaccountNumberError('Please enter 10 digits');
            } else {
                setaccountNumberError('');
            }
        }

    };

    const handleSubmitResetPassword = (event) => {
        event.preventDefault();

        if (resetPasswordFormData.currentPassword !== resetPasswordFormData.newPassword) {

            if (resetPasswordFormData.newPassword === resetPasswordFormData.confirmNewPassword) {

                resetPassword(resetPasswordFormData.currentPassword, resetPasswordFormData.newPassword).then(handleResetPasswordResponse);

            }else{

                dispatch(dialogConfirmSetData({
                    title: "Error",
                    message: "New password does not match.",
                    closeButton: "",
                    confirmButton: "OK",
                    eventConfirmButton: () => null,
                    eventCloseButton: () => null,
                    showCloseButton: false
                }));
                // ** ทำให้ dialog โชว์ขึ้นมา **
                dispatch(dialogConfirmSetShowModal(true));
            }

        } else {
            dispatch(dialogConfirmSetData({
                title: "Something Wrong.",
                message: "Unable to confirm a new password that is the same as the old password. Please try again.",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
        }

    }

    const handleResetPasswordResponse = async (response) => {
        if(response.data["error"] == true){

            dispatch(dialogConfirmSetData({
                title: "Error",
                message: response.data["message"],
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));

        }else{
            // alert('Reset password success')
            dispatch(dialogConfirmSetData({
                title: "Success",
                message: "Reset password success",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            
            resetPasswordFormRef.current.reset()
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        const penNamePublisher = formData.penNamePublisher || publisher['name'];
        const nameSurname = formData.nameSurname || publisher['real_name'];
        const idCard = formData.idCard || publisher['id_card_no'];
        const phoneNumber = formData.phoneNumber || publisher['phone_number'];
        const CorporationName = formData.CorporationName || publisher['corporation_name'];
        const taxId = formData.taxId || publisher['tax_id'];

        const address = formData.address || publisherAddress['address_detail'];
        const street = formData.street || publisherAddress['street'];
        const subdisTrict = formData.subdisTrict || publisherAddress['subdistrict'];
        const disTrict = formData.disTrict || publisherAddress['district'];
        const province = formData.province || publisherAddress['province_id'];
        const zipCode = formData.zipCode || publisherAddress['zip_code'];

        const accountingContactName = formData.accountingContactName || publisherAccountingDepartment['contact_name'];
        const accountingPhoneNumber = formData.accountingPhoneNumber || publisherAccountingDepartment['phone_number'];
        const accountingEmail = formData.accountingEmail || publisherAccountingDepartment['accountant_email'];

        const addressDocment = formData.addressDocment || publisherAddressDocument['address_detail'];
        const streetDocment = formData.streetDocment || publisherAddressDocument['street'];
        const subdistrictDocment = formData.subdistrictDocment || publisherAddressDocument['subdistrict'];
        const districtDocment = formData.districtDocment || publisherAddressDocument['district'];
        const provinceDocment = formData.provinceDocment || publisherAddressDocument['province_id'];
        const zipCodeDocment = formData.zipCodeDocment || publisherAddressDocument['zip_code'];

        const bank = formData.bank || publisherBankAccount['bank_id'];
        const accountName = formData.accountName || publisherBankAccount['account_name'];

        const accountNumber = formData.accountNumber || publisherBankAccount['account_number'];
        const accountType = formData.accountType || publisherBankAccount['account_type_id'];
        const branch = formData.branch || publisherBankAccount['branch_name'];
        const provincebank = formData.Province || publisherBankAccount['province_id'];

        accountEmailRef.current.setCustomValidity("")
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test(accountEmailRef.current.value)){
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: "Invalid Email.",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            accountEmailRef.current.setCustomValidity("Please enter a valid email address.")
            accountEmailRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {

            if (form.checkValidity() === true) {
                event.preventDefault();
                event.stopPropagation();
                showLoading();
                setAlertErrorError(false);
                updatePublisherDetail("update",CorporationName, taxId, selectedAddress, personalCorporation, penNamePublisher, nameSurname, idCard, phoneNumber,
                    address, street, subdisTrict, disTrict, province, zipCode,
                    addressDocment, streetDocment, subdistrictDocment, districtDocment, provinceDocment, zipCodeDocment,
                    bank, accountName, accountNumber, accountType, branch, provincebank, accountingContactName, accountingPhoneNumber, accountingEmail

                ).then((response) => handlePublisherDetailResponse())
            } else {
                setAlertErrorError(true);
            }
        }

        setValidated(true);


    }

    const handlePublisherDetailResponse = async (response) => {

        hideLoading();

        let uploadResult = await uploadCompanyPath();

        if (uploadResult['isUploadImageError'] == true) {
            setUploadImageErrorMessage(uploadResult['uploadImageErrorMessage']);
            setShowAlertError(true);
        }else{
            setShouldShowSaveSuccessMessage(true);
            // setShowAlertError(false);

            setTimeout(() => {
                setShouldShowSaveSuccessMessage(false);
                setValidated(false);
                setShowAlertError(false);
                setAlertErrorError(false);
                resetCompanyCertFileUploadForm();
              }, 2000);
            
        }
    }


    const _getPublisher = async () => {
        try {
            const response = await getPublisher();
            // console.log('xxxx' + response.data['result']['publisherID']);
            return response.data['result']['publisherID'];
        } catch (error) {
            // console.error("Error while getting publisher:", error);
            // Handle the error or return a default value
            return '';
        }
    };

    async function uploadCompanyPath() {

        var uploadCoverImagePath = null;
        var isUploadCoverError = false;
        var uploadCoverErrorMessage = "";

        if (fileCompanyCert != null) {
            let publisherId = await _getPublisher();
            const uploadFilePromise = uploadFileCompany(fileCompanyCert, publisherId)
                .then( async response => {
                    // console.log("upload image cover : " + response.data);
                    // console.log(response.data['error']);

                    if (response.data['access_path'] != null && response.data['error'] != true) {
                        uploadCoverImagePath = response.data['access_path'];
                        await updateCompanyCertFilePath(uploadCoverImagePath).then(response => {
                            // console.log(response.data);

                        });;

                    } else {
                        isUploadCoverError = true;
                        uploadCoverErrorMessage = "Upload company certificate error, please try again";
                    }

                });

            // console.log("start image upload");
            await Promise.all([uploadFilePromise]);
            // console.log("end image upload");
        }

        if (isUploadCoverError) {
            let result = {
                "isUploadImageError": isUploadCoverError,
                "uploadImageErrorMessage": uploadCoverErrorMessage
            };
            return result;
        } else {

            let result = {
                "isUploadPdfError": false,
                "isUploadImageError": false
            };
            return result;
        }


    }

    return (
        (isPublisherLevel() === true) ?
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <span className='title-text'> Account Settings </span>
                            <p className='context-text'> Manage your account, reset your password. </p>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Tabs id="fill-tab-example"
                                            className="tabs-box"
                                            activeKey={activeKey}
                                            onSelect={handleSelect}
                                            fill>
                                            <Tab className='tab-text' eventKey="home" title="Profile Information">
                                                <div className='box-table-Account'>
                                                    <span className='inner-title-text'>Publisher / Writer Information </span>
                                                    <hr className='hr-title' />
                                                    <Form  ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
                                                        <Form.Group as={Row} className="mb-3"    style={{}}>

                                                            <Form.Label column sm="4">
                                                                <span className='my-rowlabel'>  Personal / Corporation </span>
                                                            </Form.Label>
                                                            <Col >
                                                                <Form.Check
                                                                    id="personalCorporationPersonal"
                                                                    label="Personal"
                                                                    type='radio'
                                                                    name='personalCorporation'
                                                                    defaultValue='1'
                                                                    disabled
                                                                    style={{ marginTop: '8px !important' }}
                                                                    checked={personalCorporation === '1'}
                                                                    onChange={handleChangepersonalCorporation}
                                                                    inline
                                                                />

                                                                <Form.Check
                                                                    id="personalCorporationCoporation"
                                                                    label="Corporation"
                                                                    type='radio'
                                                                    name='personalCorporation'
                                                                    defaultValue='2'
                                                                    style={{ marginTop: '8px !important' }}
                                                                    checked={personalCorporation === '2'}
                                                                    onChange={handleChangepersonalCorporation}
                                                                    disabled={isImpersonatedLogin}
                                                                    inline
                                                                />

                                                            </Col>
                                                        </Form.Group>

                                                        {personalCorporation === '1' && (

                                                            <div id="Personal">
                                                                <Form.Group as={Row} className="mb-3" controlId="validationCustom01">
                                                                    <Form.Label column sm="4">
                                                                        Pen Name / Publisher
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control type="text" placeholder="" defaultValue={publisher['name']} name="penNamePublisher" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>


                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Name - Surname <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" placeholder="" defaultValue={publisher['real_name']} name="nameSurname" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3" controlId="idNumber">
                                                                    <Form.Label column sm="4">
                                                                        ID Card <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" pattern="[0-9]{13}" maxLength={13} isInvalid={!!idCardError} defaultValue={publisher['id_card_no']} name="idCard" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {idCardError}
                                                                        </Form.Control.Feedback>
                                                                    </Col>
                                                                </Form.Group>


                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Phone Number <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={50} placeholder="" defaultValue={publisher['phone_number']} name="phoneNumber" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>
                                                            </div>


                                                        )}

                                                        {personalCorporation === '2' && (
                                                            <div id="Corporation">
                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Pen Name / Publisher
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control type="text" placeholder="" maxLength={60} defaultValue={publisher['name']} name="penNamePublisher" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>



                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Corporation Name <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={200} placeholder="" defaultValue={publisher['corporation_name']} name="CorporationName" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Tax ID <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" pattern="[0-9]{13}" maxLength={13} isInvalid={!!taxIdError} placeholder="" defaultValue={publisher['tax_id']} name="taxId" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {taxIdError}
                                                                        </Form.Control.Feedback>
                                                                    </Col>
                                                                </Form.Group>


                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Name - Surname of The Coordinator <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" placeholder="" maxLength={200} defaultValue={publisher['real_name']} name="nameSurname" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Phone Number <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={50} placeholder="" defaultValue={publisher['phone_number']} onChange={handleChange} disabled={isImpersonatedLogin} name="phoneNumber" />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Company certification <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">

                                                                        {(uploadCompany) ?
                                                                            <div>
                                                                                {selectedFileCoverType == true ? (
                                                                                    <div >
                                                                                        <a className='normal-link link-button' onClick={handleClickPdf} >  {publisher['name'] ? publisher['name'] + '.pdf' : formData.penNamePublisher + '.pdf'}    </a>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <img src={uploadCompany} style={{ width: 250 }} />
                                                                                    </div>
                                                                                )
                                                                                }

                                                                                {!isImpersonatedLogin &&
                                                                                    <a onClick={() => deleteCompany()} className='delete-link link-button'>
                                                                                        Delete
                                                                                    </a>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <Form.Control ref={companyRef} required type="file" accept="image/jpeg ,application/pdf" value={formData.uploadCompany} name="uploadCompany" disabled={isImpersonatedLogin} onChange={handleImageChange} />
                                                                                <div>
                                                                                    <span className='text-alerts' >Supported file extensions .jpg .jpeg .pdf only</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Form.Group>

                                                                <br></br>
                                                                <span className='inner-title-text'>Accounting Department </span>
                                                                <hr className='hr-title' />

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Accounting Contact Name  <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" placeholder="" maxLength={60}  defaultValue={publisherAccountingDepartment['contact_name']} name="accountingContactName" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Accounting Phone Number  <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={50} defaultValue={publisherAccountingDepartment['phone_number']} onChange={handleChange} disabled={isImpersonatedLogin} name="accountingPhoneNumber" />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Accounting Email  <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control
                                                                         maxLength={100}
                                                                            required
                                                                            type="email"
                                                                            ref={accountEmailRef}
                                                                            placeholder=""
                                                                            defaultValue={publisherAccountingDepartment['accountant_email']}
                                                                            name="accountingEmail"
                                                                            onChange={handleChange}
                                                                            disabled={isImpersonatedLogin}
                                                                        />
                                                                    </Col>

                                                                </Form.Group>


                                                            </div>
                                                        )}

                                                        <br></br>
                                                        <span className='inner-title-text'> Address on ID Card </span>
                                                        <hr className='hr-title' />
                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Address <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required as="textarea" rows={3} maxLength={300} defaultValue={publisherAddress['address_detail']} name="address" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Street <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" placeholder="" maxLength={100}  defaultValue={publisherAddress['street']} name="street" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Subdistrict <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" placeholder=""  maxLength={50}  defaultValue={publisherAddress['subdistrict']} name="subdisTrict" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                District <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" placeholder="" maxLength={50} defaultValue={publisherAddress['district']} name="disTrict" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>



                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Province <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.province || publisherAddress['province_id']} name="province" disabled={isImpersonatedLogin} onChange={handleChange} >
                                                                    <option value=''>Please Select</option>
                                                                    {optionsProvince.map((option) => (
                                                                        <option key={option.province_id} value={option.province_id}>
                                                                            {option.province_name}
                                                                        </option>
                                                                    ))}

                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3"    >
                                                            <Form.Label column sm="4">
                                                                Zip code <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" maxLength={5} pattern="\d{1,5}" disabled={isImpersonatedLogin} onChange={handleChange} isInvalid={!!zipCodeError} defaultValue={publisherAddress['zip_code']} name="zipCode" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {zipCodeError}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>

                                                        <br></br>
                                                        <span className='inner-title-text'>Document Delivery Address</span>
                                                        <hr className='hr-title' />

                                                        <Form.Group as={Row} className="mb-12"   >
                                                            <Form.Label column sm="4">
                                                                <span className='my-rowlabel'>  Input Address  </span><span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col >
                                                                <Form.Check
                                                                    label="Input Address"
                                                                    type='radio'
                                                                    name='inputAddress'
                                                                    defaultValue='1'
                                                                    checked={selectedAddress === '1'}
                                                                    onChange={handleChangeAddress}
                                                                    inline
                                                                    disabled={isImpersonatedLogin}
                                                                />

                                                                <Form.Check
                                                                    label="Use The Address on The ID Card"
                                                                    type='radio'
                                                                    name='inputAddress'
                                                                    defaultValue='2'
                                                                    checked={selectedAddress === '2'}
                                                                    onChange={handleChangeAddress}
                                                                    inline
                                                                    disabled={isImpersonatedLogin}
                                                                />
                                                            </Col>
                                                        </Form.Group>


                                                        {selectedAddress === '1' && (
                                                            <div>
                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Address <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required as="textarea" maxLength={300} rows={3} defaultValue={publisherAddressDocument['address_detail']} name="addressDocment" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Street <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={100} placeholder="" defaultValue={publisherAddressDocument['street']} name="streetDocment" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Subdistrict <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={50} placeholder="" defaultValue={publisherAddressDocument['subdistrict']} name="subdistrictDocment" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        District <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" maxLength={50} placeholder="" defaultValue={publisherAddressDocument['district']} name="districtDocment" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Province <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Select required defaultValue={formData.provinceDocment || publisherAddressDocument['province_id']} name="provinceDocment" disabled={isImpersonatedLogin} onChange={handleChange} >
                                                                            <option value=''>Please Select</option>
                                                                            {optionsProvince2.map((option) => (
                                                                                <option key={option.province_id} value={option.province_id}>
                                                                                    {option.province_name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Form.Group>


                                                                <Form.Group as={Row} className="mb-3"   >
                                                                    <Form.Label column sm="4">
                                                                        Zip code <span className='star'>*</span>
                                                                    </Form.Label>
                                                                    <Col sm="6">
                                                                        <Form.Control required type="text" disabled={isImpersonatedLogin} maxLength={5} pattern="\d{1,5}" placeholder="" onChange={handleChange} isInvalid={!!zipCodeDocmentError} defaultValue={publisherAddressDocument['zip_code']} name="zipCodeDocment" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {zipCodeDocmentError}
                                                                        </Form.Control.Feedback>
                                                                    </Col>



                                                                </Form.Group>
                                                            </div>
                                                        )}

                                                        <br></br>
                                                        <span className='inner-title-text'>Bank Account</span>
                                                        <hr className='hr-title' />
                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Bank <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.bank || publisherBankAccount['bank_id']} name="bank" disabled={isImpersonatedLogin} onChange={handleChange} >
                                                                    <option value=''>Please Select</option>
                                                                    {optionsBank.map((option) => (
                                                                        <option key={option.bank_id} value={option.bank_id}>
                                                                            {option.bank_name}
                                                                        </option>
                                                                    ))}

                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Account Name <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" maxLength={100}  defaultValue={publisherBankAccount['account_name']} name="accountName" disabled={isImpersonatedLogin} onChange={handleChange} />

                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Account Number <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" maxLength={20} placeholder="" isInvalid={!!accountNumberError} defaultValue={publisherBankAccount['account_number']} name="accountNumber" disabled={isImpersonatedLogin} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {accountNumberError}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Account Type <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.accountType || publisherBankAccount['account_type_id']} name="accountType" disabled={isImpersonatedLogin} onChange={handleChange} >
                                                                    <option value=''>Please Select</option>
                                                                    {optionsgetBankAccount.map((option) => (
                                                                        <option key={option.account_type_id} value={option.account_type_id}>
                                                                            {option.account_type_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Branch <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" maxLength={100}  placeholder="" defaultValue={publisherBankAccount['branch_name']} name="branch" disabled={isImpersonatedLogin} onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Province <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.Province || publisherBankAccount['province_id']} name="Province" disabled={isImpersonatedLogin} onChange={handleChange} >
                                                                    <option value=''>Please Select</option>
                                                                    {optionsProvince3.map((option) => (
                                                                        <option key={option.province_id} value={option.province_id}>
                                                                            {option.province_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>
                                                        <br></br>


                                                        {AlertError === true && (
                                                            <Alert sm="6" variant="danger">
                                                                <center>
                                                                Please fill in the information correctly
                                                                </center>
                                                            </Alert>
                                                        )}

                                                        {shouldShowSaveSuccessMessage === true && (
                                                            <Alert sm="6" variant="success">
                                                                <center>
                                                                    Save successfully.
                                                                </center>
                                                            </Alert>
                                                        )}



                                                        <center>

                                                            <div>
                                                                {showAlertError && (
                                                                    <Alert variant="danger" onClose={() => setShowAlertError(false)} dismissible>
                                                                        <p>
                                                                            {showUploadImageErrorMessage}
                                                                        </p>
                                                                    </Alert>
                                                                )}
                                                            </div>

                                                            <br></br>
                                                            {!isImpersonatedLogin &&
                                                                <Button className='button-form-save button-main button-orange' variant="primary" type="submit">Save</Button>
                                                            }
                                                        </center>

                                                    </Form>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="profile" title="Reset Password">
                                                <div className='content-box-inner'>
                                                    <Form ref={resetPasswordFormRef} onSubmit={handleSubmitResetPassword}>
                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Current Password
                                                                <span className="star">*</span>
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <input className='form-control' ref={currentPasswordRef} required type="password" name="currentPassword" disabled={isImpersonatedLogin} onChange={handleChangeResetPasswordForm} placeholder=""/>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                New Password
                                                                <span className="star">*</span>
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control ref={newPasswordRef} required type="password" name="newPassword" disabled={isImpersonatedLogin} onChange={handleChangeResetPasswordForm} placeholder="" />
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3"   >
                                                            <Form.Label column sm="4">
                                                                Confirm New Password
                                                                <span className="star">*</span>
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control ref={confirmNewPasswordRef}  required type="password" name="confirmNewPassword" disabled={isImpersonatedLogin} onChange={handleChangeResetPasswordForm} placeholder="" />
                                                            </Col>
                                                        </Form.Group>
                                                        <br></br>

                                                        <center>

                                                            <div>
                                                                {shouldShowResetPasswordError && (
                                                                    <Alert variant="danger" onClose={() => setShouldShowResetPasswordError(false)}>
                                                                        <p>
                                                                            {resetPasswordErrorMessage}
                                                                        </p>
                                                                    </Alert>
                                                                )}
                                                            </div>
                                                            {!isImpersonatedLogin &&
                                                                <Button className='button-form-save button-main button-orange' type="submit">Save</Button>
                                                            }
                                                        </center>

                                                    </Form>
                                                </div>

                                            </Tab>
                                        </Tabs>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
        : ''
    );
};

export default AccountSettings;
