import React, { useState, useEffect } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Button from 'react-bootstrap/Button';
import { localeString , getManageReview, limitPageEbookDetails , searchManageReview  , deleteManageReview} from '../../api/index.js'
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal  } from '../../actions/confirmDialog.action.js';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { hideLoading, showLoading } from '../../helper/Helper.js';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setManageSortOption} from '../../actions/site.action.js'; 

const ManageReview = () => {
    const dispatch = useDispatch();
    const [dataManageRevie, setDataManageReview] = useState([]);
    const [formData, setFormData] = useState('');
    const [pageReview, setPageReview] = useState(1);
    const [lengthManageRevie, setLengthManageReview] = useState(1);

 
    const manageReviewSortOption = useSelector(state => state.site.manageReviewSortOption);
    const [sortColumn, setSortColumn] = useState(manageReviewSortOption);
    
    
    const getIcon = (columnKey) => {
      const sortItem = sortColumn.find(item => item.columnKey === columnKey);
      if (sortItem?.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      }
      if (sortItem?.direction === 'descending') {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
      return <FontAwesomeIcon icon={faSort} />;
    };
  
    const requestSort = (columnKey) => {
        setPageReview(1);
      let newSortConfig = [...sortColumn];
      const existingSortItemIndex = newSortConfig.findIndex(item => item.columnKey === columnKey);
  
      if (existingSortItemIndex !== -1) {
        if (newSortConfig[existingSortItemIndex].direction === 'ascending') {
          newSortConfig[existingSortItemIndex].direction = 'descending';
        } else if (newSortConfig[existingSortItemIndex].direction === 'descending') {
          newSortConfig.splice(existingSortItemIndex, 1);  // Remove the sort item if it's already descending
        }
      } else {
        newSortConfig.push({ columnKey: columnKey, direction: 'ascending' });
      }
  
    //   console.log(newSortConfig);
      setSortColumn(newSortConfig);
      handleShowManageReview(1 ,newSortConfig );
      dispatch(setManageSortOption(newSortConfig));
    
    };



    const handleChange = (event) => {       
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (event.key === 'Enter') {
            handleSearch();
            setSortColumn([]);
        }
    }

    const handleShowManageReview = (page , newSortConfig) => {
        showLoading();
        getManageReview(page , limitPageEbookDetails , newSortConfig).then(response => {
            setDataManageReview(response.data['queryDataReview']);
            setLengthManageReview(response.data['queryCountReview'][0]['countResponseData'])   
            hideLoading();   
        });
    }
    

    const handleSearch = () => {
        const dataKeyword = formData.keyword || '';
        const option = formData.option || 'reviewer';
        const page = 1;
        showLoading();
        searchManageReview(page , dataKeyword, option, limitPageEbookDetails).then(response => {
            setDataManageReview(response.data['queryDataReview']);
            if (response && response.data && response.data['queryCountReview'] && response.data['queryCountReview'][0]) {
                setLengthManageReview(response.data['queryCountReview'][0]['countResponseData']);
              }  
              hideLoading();   
        });
    }



    function handleDelete(reviewId) {
        dispatch(dialogConfirmSetData({
            title: "Confirm Review Deletion",
            message: " Are you sure you want to delete this review?",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => deleteReview(reviewId),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        dispatch(dialogConfirmSetShowModal(true));
    }

    function deleteReview(reviewId){
        showLoading();
        deleteManageReview(reviewId).then(response => {
            hideLoading();
            handleShowManageReview(1 , sortColumn);
            setPageReview('1');
        })
    }

    useEffect(() => {
        handleShowManageReview(pageReview , sortColumn);
    }, []);





    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <div className='div-row-col'>
                                <Row sm={12}>
                                    <span className='title-text'>Manage Review</span>
                                    <p className='context-text'>Manage review description</p>

                                    <Col sm={12} md={5}>

                                    </Col>
                                    <Col sm={12} md={7}>
                                        <InputGroup>

                                            <Form.Control style={{ width: '150px' }} className='form-control-custom' name='keyword'
                                                onKeyUp={(e) => {
                                                    handleChange(e);
                                                    // setPageReview('1');
                                                }}
                                                placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='option' onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >

                                                <option value="reviewer" >Reviewer</option>
                                                <option value="ratings" >Ratings</option>
                                                <option value="comment" >Comment</option>
                                                <option value="bookname" >Book Name</option>
                                                <option value="publisher" >Publisher</option>

                                            </Form.Select>

                                            <div md={1} style={{ marginLeft: '10px' }}>

                                                <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                    handleSearch();
                                                    setSortColumn([]);
                                                    setPageReview('1');
                                                }} >Search</Button>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>

                            </div>

                        

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col className='' >
                                            <main>
                                                <div className=''>
                                                    <div className=''>
                                                        <Table hover >
                                                            <thead className='thead-text'>
                                                                <tr>

                                                                    <th ><p>No</p></th>
                                                                    <th onClick={() => requestSort('dateReview')} >     <p className='th-Text'> Date Review <span>{getIcon('dateReview')}</span> </p></th>
                                                                    <th onClick={() => requestSort('reviewer')}   >     <p className='th-Text'> Reviewer <span>{getIcon('reviewer')}</span> </p></th>
                                                                    <th onClick={() => requestSort('rating')}     >     <p className='th-Text'> Ratings <span>{getIcon('rating')}</span></p></th>
                                                                    <th onClick={() => requestSort('comment')}    >     <p className='th-Text'> Comment <span>{getIcon('comment')}</span> </p></th>
                                                                    <th onClick={() => requestSort('bookName')}   >     <p className='th-Text'> Book Name <span>{getIcon('bookName')}</span> </p></th>
                                                                    <th onClick={() => requestSort('publisher')}  >     <p className='th-Text'> Publisher  <span>{getIcon('publisher')}</span> </p></th>
                                                                    <th ><p>Action</p></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataManageRevie.map((option, index) => (

                                                                    <tr   key={option.review_id}>
                                                                        <td style={{ width: '5px' }} >  <p className='td-text'>{(pageReview - 1) * limitPageEbookDetails + index + 1} </p> </td>
                                                                        <td style={{ width: '5px' }}  >  <p className='td-text'>{localeString(option.created_date)} </p> </td>
                                                                        <td style={{ width: '5px' }}  >  <p className='td-text'  >{option.display_name} </p> </td>
                                                                        <td style={{ width: '10px' }} >  <p className='td-text'>{option.rating} </p> </td>
                                                                        <td style={{ width: '2px' }}  >  <p className='td-text'>{option.comment} </p> </td>
                                                                        <td style={{ width: '20px' }} >  <p className='td-text'>{option.book_title} </p> </td>
                                                                        <td style={{ width: '10px' }} >  <p className='td-text'>{option.name} </p> </td>
                                                                        <td style={{ width: '10px' }} >  <p className='td-text'>{
                                                                            <a onClick={() => handleDelete(option.review_id)}
                                                                                className='delete-link link-button'>
                                                                                Delete
                                                                            </a>}
                                                                        </p>
                                                                        </td>
                                                                    </tr>

                                                                ))}

                                                            </tbody>
                                                        </Table>
                                                        {(dataManageRevie.length < 1 && formData.keyword != null) ?
                                                            <div className='search-no-result'><center><strong>No results for '{formData.keyword}'</strong></center></div> : ''
                                                        }

                                                    </div>
                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <PaginationControl
                            page={pageReview}
                            between={4}
                            total={lengthManageRevie}
                            limit={limitPageEbookDetails}
                            changePage={(page) => {
                                setPageReview(page);
                                handleShowManageReview(page , sortColumn);
                            }}
                            ellipsis={10}
                        />
                    </div>
                </Col>


            </Row>
        </div >
    );
};

export default ManageReview;
